import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import langEn from './lang.en.json';
import langKo from './lang.ko.json';

const resources = {
    en: {
        translation: langEn,

    },
    ko: {
        translation: langKo,
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: "ko",
    interpolation: {
        escapeValue: false,
    },
});

export default i18next;
