import React, {useEffect} from 'react';
import './Layout.scss';
// import {HeaderMenu} from "../component/menu/HeaderMenu";
// import {Footer} from "../component/footer/Footer";
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import i18n from "../lang/i18n";
import { useLocation } from 'react-router-dom';
// import {Content as HomeContainer} from "../component/home/Content";
// import {Content as BrandContainer} from "../component/brand/Content";


const HomeContainer = React.lazy(() => import('../component/home/Content'));
const BrandContainer = React.lazy(() => import('../component/brand/Content'));
const BoardContainer = React.lazy(() => import('../component/board/Content'));
const HeaderMenu = React.lazy(() => import('../component/menu/HeaderMenu'));
const Footer = React.lazy(() => import('../component/footer/Footer'));

const LayoutContainer: React.FunctionComponent<RouteComponentProps> = (props) => {

    const location = useLocation();

    useEffect(() => {
        const pathLang = location.pathname.split("/")[1]

        if (pathLang !== 'ko' && pathLang !== 'en') {
            return;
        }

        if (i18n.language !== pathLang) {
            i18n.changeLanguage(pathLang);
        }
    });


    return (
        <div className='root-background-color'>
            <Switch>
                <Redirect exact from={'/'} to={'/ko/home'} strict/>
                <Redirect exact from={'/:lang/board'} to={'/:lang/board/notices'} strict/>
                <Route path="/:lang/:page" component={HeaderMenu}/>
            </Switch>

            <div className="site-layout-background">
                <Switch>
                    <Route exact path="/" component={HomeContainer}/>
                    <Route exact path="/:lang" component={HomeContainer}/>
                    <Route exact path="/:lang/home" component={HomeContainer}/>
                    <Route exact path="/:lang/brand" component={BrandContainer}/>
                    <Route exact path="/:lang/board/*" component={BoardContainer}/>
                    {/*<Route exact path="/customer-support" component={BrandContainer} />*/}
                    {/*<Route exact path="/buy" component={CustomerSupportContainer} />*/}
                </Switch>

            </div>
            <Switch>
                <Route path="/:lang" component={Footer}/>
            </Switch>

        </div>
    );
}

export default withRouter(LayoutContainer);
