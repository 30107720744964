import React from 'react';
import {BrowserRouter} from "react-router-dom";
import LayoutContainer from "./layout/LayoutContainer";

function App() {
    return <BrowserRouter basename={process.env.PUBLIC_URL}>
        <React.Suspense fallback={<></>}>

            <LayoutContainer/>
        </React.Suspense>

    </BrowserRouter>

}

export default App;
